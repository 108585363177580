import "./Header.css";
import React, { useRef } from "react";
import ear from "rabbit-ear";

const showAbout = () => alert(`ALPHA RELEASE: Fatal crashes possible, backup your work often

- The flat-fold layer solver is in beta. It runs in a background thread, but if you are editing complex designs faster than the solver can keep up, it's probably best to disable it until you are done.

todo:
- before a crease happens, draw anticipated crease lines.
- make sure fold direction and arrows match the correct direction.
Origami Simulator:
- ray-caster not registering near the boundary of the origami.
- it's possible to generate CPs which do not fold for some reason.

Credits: Amanda Ghassaei (origami simulator), Jason Ku (folded state layers), Robert Lang (axiom 6)`);

const Header = (props) => {

	const inputFile = useRef(null) 

	const filesDidLoad = (event) => {
		event.stopPropagation();
		event.preventDefault();
		props.fileDialogOpen(event.target.files);
	}

	const viewList = () => ["code", "crease pattern", "simulator"]
		.map((view, i) => (
			<li
				key={i}
				id={`view-${view}`}
				highlighted={(props.view === view).toString()}
				onClick={() => props.setView(view)}>
					{view}
			</li>));

	return (
		<nav className="menubar">
			<ul>
				<li>File
					<ul className="dropdown">
						<li onClick={props.fileNew}>new</li>
						<li onClick={() => inputFile.current.click()}>open</li>
						<li onClick={props.fileSave}>save</li>
{/*						<li>export
							<ul className="dropdown">
								<li onClick={() => pressExport("fold")}>.fold</li>
								<li onClick={() => pressExport("svg")}>.svg</li>
								<li onClick={() => pressExport("png")}>.png</li>
							</ul>
						</li> */}
					</ul>
				</li>
				<li>View
					<ul className="dropdown">
						{viewList()}
					</ul>
				</li>
				<li onClick={showAbout}>About</li>
			</ul>
			<input
				type="file"
				id="file"
				ref={inputFile}
				onChange={filesDidLoad.bind(this)}
				style={{display: 'none'}}/>
		</nav>
	);
};

export default Header;
