import React from "react";
import ear from "rabbit-ear";
import Svg from "./Svg/Svg";
import OrigamiGroup from "./Svg/OrigamiGroup";
import { ToolContext } from "../Data/Context";
import AboveGroup from "./Svg/AboveGroup";
import BelowGroup from "./Svg/BelowGroup";
import PaperGroup from "./Svg/PaperGroup";
import TouchManager from "./TouchManager";

// const keys = ["edge","edges_assignment","edges_faces","edges_foldAngle","edges_vertices","face","faces_edges","faces_faces","faces_vertices","vertex","vertices_coords","vertices_edges","vertices_faces","vertices_vertices"];

class SVGManager extends React.Component {

	// this.props.origami can be the crease pattern or the folded origami
	constructor (props) {
		super(props);
		this.svgComponent = React.createRef();
		this.belowGroup = React.createRef();
		this.aboveGroup = React.createRef();
	}

	componentDidMount() {
		this.svgComponent.current.forceUpdate();
	}

	// buildPoint (event) {
	// 	const point = [event.x, event.y];
	// 	point.x = event.x;
	// 	point.y = event.y;
	// 	return point;
	// }

	// updateVector () {
	// 	if (!this.mouse.press) { return; }
	// 	this.mouse.vector = ear.vector(ear.math.subtract2(
	// 		this.mouse.position, this.nearest.press.vertices_coords));
	// 	this.mouse.vector16 = ear.vector(sixteenth(this.mouse.vector));
	// }

	redraw () {
		// update children
		this.belowGroup.current.forceUpdate();
		this.aboveGroup.current.forceUpdate();
	}

	onPress(event) {
		TouchManager.presses.push({
			position: event, // this.buildPoint(event),
			nearest: TouchManager.buildNearest(this.props.origami, event),
		});
		this.props.pointerDidUpdate();
		this.redraw();
	}

	onMove(event) {
		TouchManager.current.position = event; // this.buildPoint(event);
		TouchManager.current.nearest = TouchManager
			.buildNearest(this.props.origami, event);
		this.props.pointerDidUpdate();
		this.redraw();
	}

	onRelease(event) {
		TouchManager.releases.push({
			position: event, // this.buildPoint(event),
			nearest: TouchManager.buildNearest(this.props.origami, event),
		});
		this.props.pointerDidUpdate();
		this.redraw();
	}

	// getStrokeWidth (vmin = 1) {
	// 	const edges_lengths = this.props.origami.edges_vertices
	// 		.map(vertices => vertices
	// 			.map(v => this.props.origami.vertices_coords[v]))
	// 		.map(segment => ear.math.distance2(segment[0], segment[1]));
	// 	let shortest = edges_lengths[0];
	// 	edges_lengths.forEach(l => { if (l < shortest) { shortest = l; } });
	// 	// return Math.max(vmin / 500, Math.min(shortest / 6, vmin / 200));
	// 	// return Math.max(vmin / 750, Math.min(shortest / 6, vmin / 200));
	// 	return Math.min(shortest / 4, vmin / 200);
	// }

	// slightly more expensive than necessary. this sorts the edge
	// lengths to find the top 10% shortest, base the stroke width off it.
	getStrokeWidth (vmin = 1) {
		const edges_lengths = this.props.origami.edges_vertices
			.map(vertices => vertices
				.map(v => this.props.origami.vertices_coords[v]))
			.map(segment => ear.math.distance2(segment[0], segment[1]))
			.sort((a, b) => a - b);
		let tenth_percent = Math.floor(edges_lengths.length * 0.1);
		return Math.min(edges_lengths[tenth_percent] / 12, vmin / 200);
	}

	render() {
		// console.log("SVG Manager render()");
		const bounds = this.props.origami.boundingBox();
		const viewBox = [bounds.origin[0], bounds.origin[1], bounds.width, bounds.height];
		const vmin = bounds.width < bounds.height ? bounds.width : bounds.height;
		const strokeWidth = this.getStrokeWidth(vmin);
		return (<>
			<ToolContext.Consumer>
				{({ tool }) => (
					<Svg
						workspace={this.props.workspace}
						ref={this.svgComponent}
						size={viewBox}
						padding={vmin / 40}
						strokeWidth={strokeWidth / 2}
						onPress={(...args) => this.onPress(...args)}
						onMove={(...args) => this.onMove(...args)}
						onRelease={(...args) => this.onRelease(...args)}>
						<PaperGroup
							origami={this.props.origami}
							showFoldedForm={this.props.showFoldedForm}
							strokeWidth={strokeWidth}
							/>
						<BelowGroup
							ref={this.belowGroup}
							origami={this.props.origami}
							tool={tool}
							toolValues={this.props.toolValues}
							pointer={TouchManager}
							/>
						<OrigamiGroup
							origami={this.props.origami}
							showFoldedForm={this.props.showFoldedForm}
							strokeWidth={strokeWidth}
							/>
						<AboveGroup
							ref={this.aboveGroup}
							origami={this.props.origami}
							tool={tool}
							toolValues={this.props.toolValues}
							keyboard={this.props.keyboard}
							vmin={vmin}
							strokeWidth={strokeWidth}
							pointer={TouchManager}
						/>
					</Svg>
				)}
			</ToolContext.Consumer>
		</>);
	}
};

export default SVGManager;
