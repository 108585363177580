import React from "react";

// export const CPContext = React.createContext({
// 	cp: null,
// 	setCP: () => {},
// });

export const KeyboardContext = React.createContext({
	shiftKey: false,
	setShiftKey: () => {},
})

export const ToolContext = React.createContext({
	tool: "line",
	setTool: () => {},
});

// todo: get user's language, from local storage, or from location
// export const LanguageContext = React.createContext({
// 	language: "en",
// 	setLanguage: () => {},
// });
