import ear from "rabbit-ear";
const valid_assignment = { M: true, m: true, V: true, v: true };

export const ray_stop = (cp, ray) => {
	const edges_coords = cp.edges_vertices
		.map(ev => ev.map(v => cp.vertices_coords[v]));
	const edges_vector = edges_coords
		.map(coords => ear.math.subtract2(coords[1], coords[0]));
	const edges_intersections = edges_vector
		.map((vector, i) => ear.math.intersect_line_line(vector, edges_coords[i][0], ray.vector, ray.origin, ear.math.include_s, ear.math.exclude_r));
	// make a shorter array only containing the indices of the edges which intersect
	const edges_indices = edges_intersections
		.map((point, i) => point === undefined ? undefined : i)
		.filter(a => a !== undefined);
	// if no intersections exist, return undefined
	if (edges_indices.length === 0) { return; }
	const edges_distances = edges_indices
		.map(i => ear.math.distance2(edges_intersections[i], ray.origin));
	let small = 0;
	for (let i = 0; i < edges_distances.length; i++) {
		if (edges_distances[i] < edges_distances[small]) { small = i; }
	}
	return [
		[ray.origin[0], ray.origin[1]],
		[edges_intersections[edges_indices[small]]]
	];
}

export const make_kawasaki_vectors = (cp, vertex) => {
	const edges = cp.vertices_edges[vertex];
	const edges_assignment = edges.map(e => cp.edges_assignment[e]);
	// if the vertex lies on a boundary edge, this operation is unsolvable
	const contains_boundary = edges_assignment
		.map(a => a === "B" || a === "b")
		.reduce((a, b) => a || b, false);
	if (contains_boundary) { return []; }
	// get only the edges which have mountain and valley creases
	// todo: get only flat folded creases
	const valid_edges = edges
		.filter((_, i) => valid_assignment[edges_assignment[i]]);
	// for each edge, get the other vertex which isn't the shared center vertex.
	const other_vertices = valid_edges
		.map(e => cp.edges_vertices[e])
		.map(verts => verts[0] === vertex ? verts[1] : verts[0]);
	const edges_vectors = other_vertices.map(v => ear.math.subtract2(
		cp.vertices_coords[v],
		cp.vertices_coords[vertex]))
		.map(vector => ear.math.normalize2(vector));
	// sort vectors edges clockwise. if vertices_edges is built properly,
	// this is unnecessary, but let's do this just incase.
	const edges_vectors_order = ear.math
		.counter_clockwise_order2(...edges_vectors);
	const edges_vectors_in_order = edges_vectors.length < 2
		? edges_vectors
		: edges_vectors_order.map(i => edges_vectors[i]);
	// filter here, this is no longer 1:1 match with vertices_edges
	return ear.vertex
		.kawasaki_solutions_vectors(edges_vectors_in_order)
		.filter(a => a !== undefined);	
}
/**
 * @description get the index of the segment nearest to the point
 * @param all different vectors
 * @param one common origin
 */
export const nearest_ray = (vectors, origin, point) => {
	const nearest_point = vectors
		.map((vector, i) => ear.math.nearest_point_on_line(
			vector,
			origin,
			point,
			ear.math.segment_limiter));
	const nearest_point_distances = nearest_point
		.map(p => ear.math.distance2(p, point));
	let nearest_solution = 0;
	nearest_point_distances.forEach((dist, i) => {
		if (dist < nearest_point_distances[nearest_solution]) {
			nearest_solution = i;
		}
	});
	return nearest_solution;
};

export const bisect = (press, release) => {
	const press_line = ear.line.fromPoints(...press.nearest.edges_coords);
	const release_line = ear.line.fromPoints(...release.nearest.edges_coords);
	// console.log("lines", press_line, release_line);
	const intersection = press_line.intersect(release_line);
	// console.log("intersection", intersection);
	// if an intersection doesn't exist, exit
	if (intersection === undefined) { return undefined; }
	// get vectors from the intersection to the press and release locations
	const press_vector = ear.math.subtract2(
		[press.position.x, press.position.y],
		intersection);
	const release_vector = ear.math.subtract2(
		[release.position.x, release.position.y],
		intersection);
	// for each line, get the direction of the line which also points in the same
	// direction that the touches came from
	// console.log("vector", press_line.vector.dot(press_vector));
	const press_line_vector = (press_line.vector.dot(press_vector) > 0)
		? press_line.vector
		: ear.math.flip(press_line.vector);
	const release_line_vector = (release_line.vector.dot(release_vector) > 0)
		? release_line.vector
		: ear.math.flip(release_line.vector);
	const bisect = ear.math.add2(
		ear.math.normalize2(press_line_vector),
		ear.math.normalize2(release_line_vector));
	return ear.ray(bisect, intersection);
};
