import "./Toolbar.css";
import React from "react";
import { selection, simple, axioms, other, modify } from "../Data/ToolTypes";

const disabled_folded = {
	"inspect": false,
	"remove": true,
	"line": false,
	"ray": true,
	"segment": true,
	"point-to-point": false,
	"line-to-line": false,
	"perpendicular-to": false,
	"point-to-line-point": false,
	"point-to-line-point-to-line": false,
	"point-to-line-line": false,
	"bisect": true,
	"kawasaki": true,
	"mountain-valley": true,
	"flat": true,
	// "flat-all": true,
	"fold-angle": true,
	"repair": true,
}

const Toolbar = (props) => {

	const buttons = [selection, simple, axioms, other, modify]
		.map(group => group.map((className, i) => (
			<button
				key={`button-${i}`}
				className={className}
				disabled={props.showFoldedForm ? disabled_folded[className] : false}
				active={(className === props.tool).toString()}
				onClick={(e) => props.setTool(e.target.parentElement.className)}>
				<i />
			</button>
		)));

	return (
		<div className="toolbar cp-toolbar">
			{buttons[0]}
			<div className="toolbar-spacer" />
			<div className="toolbar-spacer" />
			{buttons[1]}
			<div className="empty-button" />
			<div className="toolbar-spacer" />
			<div className="toolbar-spacer" />
			{buttons[2]}
			<div className="toolbar-spacer" />
			<div className="toolbar-spacer" />
			{buttons[3]}
			<div className="toolbar-spacer" />
			<div className="toolbar-spacer" />
			{buttons[4]}
			<button
				className={"push flat-all"}
				disabled={props.showFoldedForm ? disabled_folded["flat-all"] : false}
				onClick={(e) => props.flattenAll()}
			>
				<i />
			</button>
			<div className="toolbar-spacer" />
			<div className="toolbar-spacer" />
			<div className="checkbox">
				<input
					checked={props.showFoldedForm}
					disabled={props.valid.summary !== "valid"}
					type="checkbox"
					className="folded-form"
					onChange={() => props.toggleFoldedState()} />
				<i />
			</div>
			<button
				className="folded-form-anchor"
				disabled={props.showFoldedForm}
				active={("folded-form-anchor" === props.tool).toString()}
				onClick={(e) => props.setTool(e.target.parentElement.className)}>
				<i />
			</button>
			<div className="checkbox">
				<input
					checked={props.showFacesLayer}
					type="checkbox"
					className="faces-layer"
					onChange={() => props.toggleShowFacesLayer()} />
				<i />
			</div>
			<div className="empty-button" />
			<div className="toolbar-spacer" />
			<div className="toolbar-spacer" />
			<button
				className="push repair"
				disabled={props.showFoldedForm ? disabled_folded["repair"] : false}
				active={("repair" === props.tool).toString()}
				onClick={(e) => props.repairCreasePattern()}>
				<i />
			</button>
		</div>
	);
};

export default Toolbar;
