import React from "react";
import Panel from "../Panel";
import Svg from "./Svg";
import ear from "rabbit-ear";

class FlatFoldedPanel extends React.Component {

	constructor(props) {
		super(props);
		this.workspace = React.createRef();
	}

	render() {
		// console.log("FlatFoldedPanel render()", this.props.valid);
		const bounds = this.props.cp.boundary.boundingBox();
		const viewBox = [
			bounds.min[0], bounds.min[1],
			bounds.span[0], bounds.span[1]];
		const vmin = bounds.span[0] < bounds.span[1]
			? bounds.span[0]
			: bounds.span[1];

		// if all tests pass, draw folded form
		const folded = (this.props.valid.vertices.kawasaki.length === 0
			&& this.props.valid.vertices.maekawa.length === 0
			&& this.props.valid.edges.not_flat.length === 0)
			? this.props.folded
			: undefined;

		return (
			<Panel title={"Flat Fold"}>
				<div className="workspace" ref={this.workspace}>
					<Svg
						workspace={this.workspace}
						cp={this.props.cp}
						folded={folded}
						size={viewBox}
						vmin={vmin}
						strokeWidth={vmin / 100}
						valid={this.props.valid}
					/>
				</div>
			</Panel>
		);
	}
};

export default FlatFoldedPanel;
