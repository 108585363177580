import React from "react";
import ReactDOM from "react-dom";
import ear from "rabbit-ear";
import "./Svg.css";

class Svg extends React.Component {

	svg = null;

	constructor(props) {
		super(props);
		this.svg = ear.svg().scale(1, -1);
		this.svg.onPress = props.onPress;
		this.svg.onMove = props.onMove;
		this.svg.onRelease = props.onRelease;
	}

	// workspace ref still won't be bound by the time
	// of componentDidMount(), so make sure it runs once
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!this.svg.parent) {
			this.props.workspace.current.appendChild(this.svg);
		}
	}

	render() {
		// each frame we resize the canvas, in case the file loads
		// with a wildly different dimensions (eg. 1x1 into 600x600)
		this.svg.size(...this.props.size)
			.padding(this.props.padding)
			.strokeWidth(this.props.strokeWidth);
		return ReactDOM.createPortal(this.props.children, this.svg);
	}
};

export default Svg;
