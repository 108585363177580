import "./Toolbar.css";

// todo: this is a horrible fix. Firefox wtf? range input direction
// is defined in HTML not CSS. for shame.
const is_mobile = () => window.innerWidth < window.innerHeight;

const Toolbar = (props) => {
	return (
		<div className="toolbar simulator-toolbar">
			{["rotate", "grab"].map((className, i) => (
				<button
					key={i}
					className={className}
					active={(className === props.tool).toString()}
					onClick={(e) => props.setTool(e.target.parentElement.className)}>
					<i />
				</button>
			))}
			<div className="toolbar-spacer" />
			<div className="checkbox">
				<input
					checked={props.isActive}
					type="checkbox"
					className="active"
					onChange={() => props.isActiveDidPress()} />
				<i />
			</div>
			<input
				type="range"
				min={0}
				max={1}
				step={0.001}
				orient={is_mobile() ? "horizontal" : "vertical"}
				value={props.foldAmount}
				onChange={(e) => props.foldAmountDidChange(e.target.value)}
				/>
			<div className="checkbox">
				<input
					checked={props.strain}
					type="checkbox"
					className="strain"
					onChange={() => props.strainDidPress()} />
				<i />
			</div>
			<div className="toolbar-spacer" />
			<button
				className="save-fold-angle"
				onClick={(e) => props.saveFoldAngle()}>
				<i />
			</button>
		</div>
	);
};

export default Toolbar;
