import "./App.css";
import "./button.css";
import React from "react";
import ear from "rabbit-ear";
import Header from "./Header/Header";
import CreasePattern from "./CreasePattern/CreasePattern";
import Simulator from "./Simulator/Simulator";
import Code from "./Code/Code";
import { ToolContext } from "./Data/Context";
import DragAndDrop from "./DragAndDrop";
import History from "./History";
import "./Mobile.css";

const keep_keys = ["file_spec", "file_creator", "file_author", "file_title", "file_description", "file_classes", "file_frames", "frame_author", "frame_title", "frame_description", "frame_attributes", "frame_classes", "frame_unit", "vertices_coords", "edges_vertices", "edges_assignment", "edges_foldAngle", "faces_vertices", "faces_edges", "edgeOrders", "faceOrders"];

const defaultExample = {"vertices_coords":[[0,0],[1,0],[1,1],[0,1],[0.41421356237309503,1],[1,0.41421356237309503],[0.7071067811865475,0.7071067811865475],[0.7071067811865475,0.2928932188134524],[0.7071067811865475,1],[0.7071067811865475,0],[1,0.7071067811865475],[0.2928932188134524,0.7071067811865475],[0,0.7071067811865475],[0.5,0.5],[0,0.5857864376269051],[0.585786437626905,0]],"edges_vertices":[[4,3],[1,5],[6,2],[4,6],[6,5],[0,7],[7,5],[2,8],[8,4],[9,1],[9,7],[7,6],[6,8],[5,10],[10,2],[0,11],[11,4],[3,12],[10,6],[6,11],[11,12],[0,13],[13,6],[3,11],[11,13],[13,7],[7,1],[12,14],[14,0],[11,14],[0,15],[15,9],[7,15]],"edges_assignment":["B","B","F","M","M","V","F","B","B","B","F","V","V","B","B","V","F","B","V","V","F","F","F","V","F","F","V","B","B","M","B","B","M"],"edges_foldAngle":[0,0,0,-180,-180,180,0,0,0,0,0,180,180,0,0,180,0,0,180,180,0,0,0,180,0,0,180,0,0,-180,0,0,-180],"faces_vertices":[[10,2,6],[10,6,5],[6,2,8],[6,8,4],[6,11,13],[11,6,4],[11,4,3],[11,3,12],[11,12,14],[11,14,0],[11,0,13],[5,7,1],[7,5,6],[7,6,13],[7,13,0],[7,0,15],[7,15,9],[7,9,1]]};

const defaultCode = `// "cp" is your crease pattern

`;

const keyboardEventToTool = (e) => {
	if (e.ctrlKey || e.altKey || e.metaKey) { return; }
	const letter = e.key.toLowerCase();
	switch (letter) {
		case "s": return "segment";
		case "r": return "ray";
		case "l": return "line";
		// case "1": return "line";
		// case "2": return "point-to-point";
		// case "3": return "line-to-line";
		// case "4": return "perpendicular-to";
		// case "5": return "point-to-line-point";
		// case "6": return "point-to-line-point-to-line";
		// case "7": return "point-to-line-line";
		case "b": return "bisect";
		case "k": return "kawasaki";
		case "v":
		case "m": return "mountain-valley";
		case "a": return "fold-angle";
		case "f": return "flat";
		case "d": return "remove";
		case "i": return "inspect";
		default: return undefined;
	}
}

const history = History();
const initialCP = ear.cp(defaultExample);
history.new(JSON.stringify(initialCP));

const App = (props) => {
	// changes will not trigger a re-render()
	const keyboard = {};

	// const [cp, setCP] = React.useState(ear.cp());
	const [cp, setCP] = React.useState(initialCP);
	const [tool, setTool] = React.useState("line");
	const [simTool, setSimTool] = React.useState("simulator-rotate");
	const [view, setView] = React.useState("crease pattern");
	const [code, setCode] = React.useState(defaultCode);
	// keyboard keys
	const [spacebar, setSpacebar] = React.useState(false);
	// const [shiftKey, setShiftKey] = React.useState(false);

	const setCPAndHistory = (cp) => {
		setCP(cp);
		const cp_string = JSON.stringify(cp);
		history.new(cp_string);
	};

	const cacheStep = () => {
		const cp_string = JSON.stringify(cp);
		history.new(cp_string);
		// console.log("cacheStep", history);
	};

	const fileNew = () => {
		setCPAndHistory(ear.cp());
		setView("crease pattern");
	};

	const fileDialogOpen = (files) => {
		const firstFile = files[0];
		const reader = new FileReader()
		reader.onload = event => {
			try {
				setCPAndHistory(ear.cp(JSON.parse(event.target.result)));
				setView("crease pattern");
			}
			catch (error) { console.error(error); }
		};
		reader.onerror = error => console.error(error);
		reader.readAsText(firstFile)
	};

	const fileDragOpen = (FOLD) => setCPAndHistory(ear.cp(FOLD));

	const fileSave = () => {
		const export_copy = {};
		keep_keys
			.filter(key => key in cp)
			.forEach(key => { export_copy[key] = cp[key]; });
		if (defaultCode.replace(/\s/g, "") !== code.replace(/\s/g, "")) {
			// export_copy["re:code"] = JSON.stringify(code);
			export_copy["re:code"] = code.replace(/\/\/ \"cp\" is your crease pattern\n/g, "");
		}
		const cp_string = JSON.stringify(export_copy);

		const element = document.createElement('a');
		element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(cp_string));
		element.setAttribute('download', "crease-pattern.fold");
		element.style.display = 'none';
		document.body.appendChild(element);
		element.click();
		document.body.removeChild(element);
	};

	window.onkeydown = (e) => {
		// console.log("onkeydown", e);
		if (e.key.toLowerCase() === "z" && (e.ctrlKey || e.metaKey)) {
			// undo
			if (!e.shiftKey) {
				// console.log("undo", history);
				if (history.undo().get()) {
					setCP(ear.cp(JSON.parse(history.get())));
				}
			}
			// redo
			if (e.shiftKey) {
				// console.log("redo", history);
				if (history.redo().get()) {
					setCP(ear.cp(JSON.parse(history.get())));
				}
			}
			return;
		}
		const tool = keyboardEventToTool(e);
		if (tool) {
			setTool(tool);
		}
		if (e.key === " ") { setSpacebar(true); }
		// if (e.key === "Shift") { setShiftKey(true); }
		if (e.key === "Shift") { keyboard.shiftKey = true; }
	};

	window.onkeyup = (e) => {
		if (e.key === " ") { setSpacebar(false); }
		// if (e.key === "Shift") { setShiftKey(false); }
		if (e.key === "Shift") { keyboard.shiftKey = false; }
	}

	const renderView = (v) => {
		switch (v) {
			case "code":
				return <Code
					cp={cp}
					setCP={setCP}
					setView={setView}
					code={code}
					setCode={setCode} 
				/>;
			case "simulator":
				return <Simulator
					cp={cp}
					tool={simTool}
					setTool={setSimTool}
				/>;
			case "crease pattern":
			default:
				return <CreasePattern
					cp={cp}
					cacheStep={cacheStep}
					tool={tool}
					setTool={setTool}
					spacebar={spacebar}
					// shiftKey={shiftKey}
					keyboard={keyboard}
				/>;
		}
	};

	return (<>
		<ToolContext.Provider value={{ tool, setTool }}>
			<Header
				view={view}
				setView={setView}
				cp={cp}
				setCP={setCP}
				fileNew={fileNew}
				fileDialogOpen={fileDialogOpen}
				fileSave={fileSave}
			/>
			{renderView(view)}
		</ToolContext.Provider>
		<DragAndDrop fileDidLoad={fileDragOpen} />
	</>);
};

export default App;
