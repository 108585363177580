import React from "react";
import ReactDOM from "react-dom";
import ear from "rabbit-ear";

const cpStyle = {
	edges: {
		mountain: { stroke: "black" },
		valley: { stroke: "black" },
		flat: { stroke: "black", "stroke-width": 0.0025 },
		unassigned: { stroke: "black", "stroke-width": 0.0025  },
	},
	boundaries: { fill: "none" },
	faces: { fill: "none" }
};

const foldedStyle = {
	faces: {
		front: { fill: "#38c", "stroke-width": 0.01 },
		back: { fill: "#fb4", "stroke-width": 0.01 },
	}
};

class Svg extends React.Component {

	svg = null;

	constructor(props) {
		super(props);
		this.svg = ear.svg().scale(1, -1);
		this.svg.onPress = props.onPress;
		this.svg.onMove = props.onMove;
		this.svg.onRelease = props.onRelease;
	}

	// workspace ref still won't be bound by the time
	// of componentDidMount(), so make sure it runs once
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!this.svg.parent) {
			this.props.workspace.current.appendChild(this.svg);
		}
	}

	centerFolded (target, boundingBox) {
		return ear.math.subtract2(ear.math.subtract2(target, boundingBox.origin), ear.math.scale2([boundingBox.width, boundingBox.height], 0.5));
		// return ear.math.subtract2(ear.math.add2(ear.math.subtract2([0, 0], boundingBox.min), target), ear.math.scale2(boundingBox.span, 0.5));
	}

	render() {
		// 0.1 * height is the padding between cp and folded form
		const height = this.props.folded
			? this.props.size[3] * 2.1
			: this.props.size[3];
		// each frame we resize the canvas, in case the file loads
		// with a wildly different dimensions (eg. 1x1 into 600x600)
		this.svg
			.size(this.props.size[2], height)
			.padding(this.props.vmin / 50);
		this.svg.removeChildren();

		// draw and style crease pattern
		const drawing = this.svg.origami(this.props.cp, cpStyle);
		drawing.boundaries.remove();
		if (drawing.edges.boundary) {
			drawing.edges.boundary.strokeWidth(this.props.strokeWidth * 2);
		}
		if (drawing.edges.mountain) {
			drawing.edges.mountain.strokeWidth(this.props.strokeWidth);
		}
		if (drawing.edges.valley) {
			drawing.edges.valley.strokeWidth(this.props.strokeWidth);
		}
		if (drawing.edges.unassigned) {
			drawing.edges.unassigned.strokeWidth(this.props.strokeWidth / 2);
		}
		if (drawing.edges.flat) {
			drawing.edges.flat.strokeWidth(this.props.strokeWidth / 2);
		}

		// foldability indicators, edges which have 3D fold angles
		this.props.valid.edges.not_flat
			.map(e => this.props.cp.edges_vertices[e]
				.map(v => this.props.cp.vertices_coords[v]))
			.map(s => drawing.line(s[0][0], s[0][1], s[1][0], s[1][1])
				.strokeWidth(this.props.strokeWidth * 3)
				.strokeLinecap("round")
				.stroke("#e53"));

		// foldability indicators, vertices which violate Kawasaki or Maekawa
		this.props.valid.vertices.kawasaki.forEach(v => drawing
			.circle(this.props.cp.vertices_coords[v])
			.radius(this.props.vmin / 50)
			.fill("#e53"));
		this.props.valid.vertices.maekawa.forEach(v => drawing
			.circle(this.props.cp.vertices_coords[v])
			.radius(this.props.vmin / 80)
			.fill("#fb4"));

		// draw folded form if it exists (if folded vertices pass validation)
		if (this.props.folded) {
			// get the translation needed to move the folded form to the center
			const foldedTranslate = this.centerFolded([
					this.props.size[2] * 0.5,
					this.props.size[3] * 1.6, // 0.1 * height is padding
				], this.props.folded.boundingBox());
			const style = this.props.folded.faces_layer
				? foldedStyle
				: foldedStyle;
			this.svg.origami(this.props.folded, style, false)
				.translate(...foldedTranslate);
		}
		return ReactDOM.createPortal(this.props.children, this.svg);
	}
};

export default Svg;
