const History = function () {
	// newest entry will be inserted at the beginning (slot 0)
	const stack = [];
	let pointer = 0;
	const history = {};

	// public variable. this can be changed
	history.limit = 50;

	history.get = () => stack[pointer];

	history.new = (fold) => {
		if (pointer > 0) {
			stack.splice(0, pointer);
			pointer = 0;
		}
		stack.unshift(fold);
		while (stack.length > history.limit) { stack.pop(); }
		return history;
	};

	history.redo = () => {
		pointer--;
		validate();
		return history;
	};

	history.undo = () => {
		pointer++;
		validate();
		return history;
	};

	// make sure the pointer is in the bounds of the stack.
	const validate = () => {
		if (pointer < 0) { pointer = 0; }
		if (pointer >= stack.length) { pointer = stack.length - 1; }
	};

	return history;
};

export default History;
