import React from "react";
import ear from "rabbit-ear";
import cp_style from "./cp_style";
import folded_style from "./folded_style";

const edge_types = [
	"boundary",
	"mountain",
	"valley",
	"flat",
	"unassigned",
];

class Group extends React.Component {
	// the svg <g> group layer
	group = null;

	constructor(props) {
		super(props);
		this.container = React.createRef();
		this.group = ear.svg.g()
	}

	componentDidMount() {
		this.container.current.appendChild(this.group);
	}

	render() {
		this.group.removeChildren();
		// draw the crease pattern with no parent, yet.
		// if (this.props.origami.file_classes.contains("foldedState"))
		cp_style.edges.mountain["stroke-width"] = this.props.strokeWidth;
		cp_style.edges.valley["stroke-width"] = this.props.strokeWidth;
		cp_style.edges.flat["stroke-width"] = this.props.strokeWidth / 2;
		cp_style.edges.unassigned["stroke-width"] = this.props.strokeWidth / 2;

		folded_style.edges.boundary["stroke-width"] = this.props.strokeWidth / 4;
		folded_style.edges.mountain["stroke-width"] = this.props.strokeWidth / 4;
		folded_style.edges.valley["stroke-width"] = this.props.strokeWidth / 4;
		folded_style.edges.flat["stroke-width"] = this.props.strokeWidth / 4;
		folded_style.edges.unassigned["stroke-width"] = this.props.strokeWidth / 4;

		if (this.props.origami.faces_layer) {
			edge_types.forEach(type => {
				folded_style.edges[type].display = "none";
			});
		} else {
			edge_types.forEach(type => {
				delete folded_style.edges[type].display;
			});
		}

		const drawing = this.props.showFoldedForm
			? ear.svg.origami(this.props.origami, folded_style, false)
			: ear.svg.origami(this.props.origami, cp_style);
		// append only the edges.
		if (this.props.showFoldedForm) {
			this.group.appendChild(drawing);
		} else {
			this.group.appendChild(drawing.edges);
		}
		// remove the boundary edges
		if (drawing.edges.boundary && !this.props.showFoldedForm) {
			drawing.edges.boundary.remove();
		}
		return (
			<g className="cp-group" ref={this.container}></g>
		);
	}
};

export default Group;
