import ear from "rabbit-ear";
import { ray_stop, make_kawasaki_vectors, nearest_ray, bisect } from "../Math";

const sixteenth_angles = Array.from(Array(17))
		.map((_, i) => ((Math.PI * 2) / 16) * i);

export const sixteenth = (vector) => {
	let radians = Math.atan2(vector[1], vector[0]);
	while (radians < 0) { radians += Math.PI * 2; }
	const index = sixteenth_angles
		.map((rad, i) => ({ diff: Math.abs(radians - rad), i }))
		.sort((a, b) => a.diff - b.diff)
		.shift()
		.i;
	return [
		Math.cos(sixteenth_angles[index]),
		Math.sin(sixteenth_angles[index])
	];
};

const flip_assignment = {
	B: "B", M: "V", V: "M", F: "V", U: "V",
	b: "B", m: "V", v: "M", f: "V", u: "V",
};

const flatten_assignment = {
	B: "B", M: "F", V: "F", F: "F", U: "F",
	b: "B", m: "F", v: "F", f: "F", u: "F",
};

const assignment_direction = {
	B: 0, M: -1, V: 1, F: 0, U: 0,
	b: 0, m: -1, v: 1, f: 0, u: 0,
};

const axiomNeedsTable = [null, [1,1], [1,1], [1,1], [1,1], [2,1], [2,2], [2,1]];

const formatAxiomParams = (axiom, presses, releases) => {
	const makePoint = touch => touch.nearest.vertices_coords;
	const makeLine = touch => ear.line.fromPoints(...touch.nearest.edges_coords);
	switch (axiom) { // careful, number type, not string
		case 1:
		case 2: return {
			points: [...presses, ...releases]
				.map(touch => makePoint(touch))
		};
		case 3: return {
			lines: [...presses, ...releases]
				.map(touch => makeLine(touch))
		};
		case 4: return {
			points: [...releases].map(t => makePoint(t)),
			lines: [...presses].map(t => makeLine(t)),
		};
		case 5: return {
			points: [...presses].map(t => makePoint(t)),
			lines: [...releases].map(t => makeLine(t)),
		};
		case 6: return {
			points: [...presses].map(t => makePoint(t)),
			lines: [...releases].map(t => makeLine(t)),
		};
		case 7: return {
			points: [presses[0]].map(t => makePoint(t)),
			lines: [presses[1], ...releases]
				.map(t => makeLine(t)),
		};
		default: return {};
	}
};
/**
 * @param {graph} a FOLD graph. either CP or foldedForm
 * @returns {boolean} false if the required number of touches are not yet satisfied,
 * true if the operation was attempted (not even, succeded, just attempted).
 */
export const tryAxiom = (graph, TouchManager, axiom, isFoldedForm = false, face = 0) => {
	const need = axiomNeedsTable[axiom];
	if (TouchManager.presses.length < need[0]
		|| TouchManager.releases.length < need[1]) { return false;}
	const params = formatAxiomParams(axiom, TouchManager.presses, TouchManager.releases);
	// const res = ear.axiom(axiom, params, graph.boundary);
	const res = ear.axiom(axiom, params);
	// console.log(`Axiom ${axiom}`, "params", params, "result", res);
	// if (axiom === 3) {
	// 	console.log("axiom 3", res);
	// }
	if (res.length) {
		if (isFoldedForm) {
	    graph.faces_matrix2 = ear.graph.make_faces_matrix2(graph, face);
			res.forEach(line => {
		    // ear.graph.flat_fold(graph, line.vector, line.origin, "F");
		    ear.graph.flat_fold(graph, line.vector, line.origin);
		    delete graph.faces_layer;
			});
			delete graph.faces_matrix2;
		} else {
			res.forEach(line => {
				const crease = graph.line(line);
				if (crease) { crease.flat(); }
			});
		}
	}
	return true;
};

export const tryCreaseLine = (graph, TouchManager, type = "line", toolValues = {}, keyboard = {}, isFoldedForm = false, face = 0) => {
	const press = TouchManager.presses[TouchManager.presses.length - 1];
	const release = TouchManager.releases[TouchManager.releases.length - 1];
	if (press === undefined || release === undefined) { return false; }
	const start = press.nearest.vertices_coords;
	let end = release.nearest.vertices_coords;
	// 22.5
	if (keyboard.shiftKey && (type === "line" || type === "ray")) {
		const vector = ear.math.subtract2(
			[TouchManager.current.position.x, TouchManager.current.position.y],
			start);
		const vector22_5 = sixteenth(vector);
		// todo get length
		end = ear.math.add2(
			TouchManager.presses[0].nearest.vertices_coords,
			ear.math.scale2(vector22_5, 0.2));
	}
	// only if the points are different
	if (ear.math.equivalent_vector2(start, end)) { return true; }
	const res = ear[type].fromPoints(start, end);
	if (type === "ray" && toolValues.rayStop) {
		const segment = ray_stop(graph, res);
		if (segment) {
			graph.segment(segment).flat();
		}
	} else {
		if (isFoldedForm) {
	    graph.faces_matrix2 = ear.graph.make_faces_matrix2(graph, face);
	    ear.graph.flat_fold(graph, res.vector, res.origin);
	    delete graph.faces_layer;
			delete graph.faces_matrix2;
		} else {
			// default case, segment, ray, or line, not flat folded state.
			graph[type](res).flat();
		}
	}
	// even if the line fails, clear the touch history
	return true;
};

export const tryBisect = (graph, TouchManager, rayStop = false) => {
	if (!TouchManager.presses.length || !TouchManager.releases.length) { return false; }
	const ray = bisect(TouchManager.presses[0], TouchManager.releases[0]);
	if (ray) {
		if (rayStop) {
			const segment = ray_stop(graph, ray);
			if (segment) {
				graph.segment(segment).flat();
			}
		} else {
			graph.ray(ray).flat();
		}
	}
	return true;	
};

// todo
// back inside rabbit ear
// make sure all crease methods return the parts of the graph that were modified
// also, add some way to crease a ray, but the ray stops at the first edge.
export const tryKawasaki = (graph, TouchManager, rayStop = false) => {
	if (!TouchManager.presses.length || !TouchManager.releases.length) { return false; }
	const bounds = graph.boundary.boundingBox();
	const vmin = bounds.span[0] < bounds.span[1] ? bounds.span[0] : bounds.span[1];
	// this exact same code exists inside AboveGroup, they should match so that
	// the UI visual feedback matches the computed nearest item from the list.
	const vertex = TouchManager.current.nearest.vertex;
	const kawasaki_vectors_scaled = make_kawasaki_vectors(graph, vertex)
		.map(vector => ear.math.scale2(vector, vmin * 0.15));
	const nearest_solution = nearest_ray(
		kawasaki_vectors_scaled,
		graph.vertices_coords[vertex],
		[TouchManager.current.position.x, TouchManager.current.position.y]);
	const vector = kawasaki_vectors_scaled[nearest_solution];
	if (vector) {
		const ray = ear.ray(vector, graph.vertices_coords[vertex]);
		if (rayStop) {
			const segment = ray_stop(graph, ray);
			if (segment) {
				graph.segment(segment).flat();
			}
		} else {
			graph.ray(ray).flat();
		}
	}
	return true;
};
/**
 * @param {graph} a FOLD graph, I think this needs to be CP, not foldedForm.
 */
// requires: 1 press
export const tryRemove = (graph, TouchManager) => {
	const press = TouchManager.presses[TouchManager.presses.length - 1];
	if (press === undefined) { return false; }
	if (TouchManager.current.nearest.edges_assignment === "B"
		|| TouchManager.current.nearest.edges_assignment === "b") {
		console.log("boundary remove prevented");
		return true;
	}
	graph.removeEdge(press.nearest.edge);
	// graph.fragment();
	// graph.populate();
	return true;
};

// requires: 1 press
export const tryMountainValley = (graph, TouchManager) => {
	const press = TouchManager.presses[TouchManager.presses.length - 1];
	if (press === undefined) { return false; }
	const edge = press.nearest.edge;
	graph.edges_assignment[edge] = flip_assignment[
		graph.edges_assignment[edge]];
	graph.edges_foldAngle[edge] = ear.graph
		.edges_assignment_degrees[graph.edges_assignment[edge]];
	return true;
};

// requires: 1 press
export const tryFlat = (graph, TouchManager) => {
	const press = TouchManager.presses[TouchManager.presses.length - 1];
	if (press === undefined) { return false; }
	const edge = press.nearest.edge;
	graph.edges_assignment[edge] = flatten_assignment[
		graph.edges_assignment[edge]];
	graph.edges_foldAngle[edge] = ear.graph
		.edges_assignment_degrees[graph.edges_assignment[edge]];
	return true;
};

// requires: 1 press
export const tryFoldAngle = (graph, TouchManager, foldAngle) => {
	const press = TouchManager.presses[TouchManager.presses.length - 1];
	if (press === undefined) { return false; }
	if (isNaN(foldAngle)) { return true; }
	const edge = press.nearest.edge;
	graph.edges_foldAngle[edge] = foldAngle
		* assignment_direction[graph.edges_assignment[edge]];
	return true;
};
