import React from "react";
import ReactDOM from "react-dom";
import ear from "rabbit-ear";


const cpStyle = {
	edges: {
		boundary:   { stroke: "black" },
		mountain:   { stroke: "#e53"  },
		valley:     { stroke: "#38c"  },
		flat:       { stroke: "#ccc"  },
		unassigned: { stroke: "#000"  },
	},
	// boundaries: { fill: "none" },
	// faces: { fill: "none" }
};

class Svg extends React.Component {

	svg = null;

	constructor(props) {
		super(props);
		this.svg = ear.svg();
		this.svg.onPress = props.onPress;
		this.svg.onMove = props.onMove;
		this.svg.onRelease = props.onRelease;
	}

	// svgParent ref still won't be bound by the time
	// of componentDidMount(), so make sure it runs once
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!this.svg.parent) {
			this.props.svgParent.current.appendChild(this.svg);
		}
	}

	render() {
		// each frame we resize the canvas, in case the file loads
		// with a wildly different dimensions (eg. 1x1 into 600x600)
		// if (!this.svg) { return (<></>); }
		this.svg
			.size(this.props.bounds.span[0], this.props.bounds.span[1])
			// .size(this.props.size[0], this.props.size[1], this.props.size[2], this.props.size[3])
			.padding(this.props.vmin / 50);

		this.svg.removeChildren();

		const drawing = this.svg.origami(this.props.cp, cpStyle);
		// drawing.boundaries.remove();
		if (drawing.edges.boundary) {
			drawing.edges.boundary.strokeWidth(this.props.strokeWidth * 2);
		}
		if (drawing.edges.mountain) {
			drawing.edges.mountain.strokeWidth(this.props.strokeWidth);
		}
		if (drawing.edges.valley) {
			drawing.edges.valley.strokeWidth(this.props.strokeWidth);
		}
		if (drawing.edges.unassigned) {
			drawing.edges.unassigned.strokeWidth(this.props.strokeWidth / 2);
		}
		if (drawing.edges.flat) {
			drawing.edges.flat.strokeWidth(this.props.strokeWidth / 2);
		}

		return ReactDOM.createPortal(this.props.children, this.svg);
	}
};

export default Svg;
