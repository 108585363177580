import React from "react";
import Panel from "../Panel";
import ToolInfo from "../../Data/ToolsInfo";
import InspectToolPanel from "./InspectToolPanel";
import AxiomToolPanel from "./AxiomToolPanel";
import AssignmentToolPanel from "./AssignmentToolPanel";
import FoldAngleToolPanel from "./FoldAngleToolPanel";
import "./ToolPanels.css";

// const capitalize = word => word
//   .toLowerCase()
//   .replace(/\w/, firstLetter => firstLetter.toUpperCase());

const PanelForTool = (tool) => {
	switch (tool) {
		case "inspect": return InspectToolPanel;
		case "remove": return AssignmentToolPanel;
		case "line":
		case "ray":
		case "segment":
		case "point-to-point":
		case "line-to-line":
		case "perpendicular-to":
		case "point-to-line-point":
		case "point-to-line-point-to-line":
		case "point-to-line-line":
		case "bisect": 
		case "kawasaki": return AxiomToolPanel;
		case "mountain-valley":
		case "flat": return AssignmentToolPanel;
		case "fold-angle": return FoldAngleToolPanel;
		default: return InspectToolPanel;
	}
};

class ToolPanel extends React.Component {

	emptyToolPanel () {
		return <Panel title={""}></Panel>;
	}

	render() {
		const toolInfo = ToolInfo[this.props.tool];
		const ComponentClass = PanelForTool(this.props.tool);
		return toolInfo === undefined ? this.emptyToolPanel() : (
			<Panel title={toolInfo.title}>
				<ComponentClass
					pointer={this.props.pointer}
					cp={this.props.cp}
					toolInfo={toolInfo}
					toolValues={this.props.toolValues}
					toolValueDidChange={this.props.toolValueDidChange}
				/>
			</Panel>
		);
	}
}

export default ToolPanel;
