const style = {
	boundaries: {
		display: "none",
		// "stroke-width": 0.0025,
	},
	faces: {
		front: { fill: "#38c" },
		back: { fill: "#fb4" },
	},
	edges: {
		// display: "none",
		unassigned: { stroke: "#ccc"  },
		flat:       { stroke: "#888" },
		mountain:   { stroke: "black"  },
		valley:     { stroke: "black"  },
		boundary:   { stroke: "black" },
	},
	vertices: {
		display: "none"
	}
};

export default style;
