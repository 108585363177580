import React from "react";
import SVGManager from "./SVGManager";
import Toolbar from "../Toolbars/CreasePatternToolbar";
import ToolsInfo from "../Data/ToolsInfo";
import ToolPanel from "../Panels/ToolPanel/";
import FlatFoldedPanel from "../Panels/FlatFoldedPanel/";
import "./CreasePattern.css";
import TouchManager from "./TouchManager";
import * as Modify from "./Modify";

// pretty fast hash function for Javascript strings
// https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
const stringToHash = (string) => {
	let hash = 0;
	for (let i = 0; i < string.length; i++) {
		hash = ((hash << 5) - hash) + string.charCodeAt(i);
		hash |= 0; // Convert to 32bit integer
	}
	return hash;
};

class CreasePattern extends React.Component {
	// the folded FOLD object
	foldedForm = undefined;
	faces_layer = undefined;
	// the object which summarizes issues with foldability
	valid = undefined;
	// the hash of the current cp being displayed
	// when the worker comes back with a face layering, we need to check
	// if the current cp being displayed is the same one the worker set out
	// to solve. if not we throw away the result.
	layer_hash = undefined;

	// the crease pattern is delivered in the props: this.props.cp
	state = {
		// faces_layer: undefined, // the array of faces_layer for the folded form
		showFoldedForm: false,
		showFacesLayer: true,
	};

	toolValues = {
		foldAngle: "90", // this is a string, parse into a number before use
		rayStop: false,
		foldedStateAnchor: [0.5001, 0.501],
		shiftKey: false,
	};

	constructor (props) {
		super(props);
		this.workspace = React.createRef();
		this.toolPanel = React.createRef();
		// this.svgComponent = React.createRef();
		this.updateCreasePattern();
	}

	componentDidMount () {
		// this.svgComponent.current.forceUpdate();
		this.worker = new Worker(new URL("../Workers/folder.worker", import.meta.url));
		this.worker.onmessage = ({ data }) => {
			if (!data || !data.faces_layers) { return; }
			// when the worker asynchronously returns with the layer order,
			// set it to the state, which triggers a rerender, and at the
			// top of render(), the array is bound to the FOLD object.
			const hash = stringToHash(JSON.stringify(this.props.cp));
			if (hash === this.layer_hash) {
				// this.foldedForm.faces_layer = data.faces_layer;
				this.faces_layer = data.faces_layers[0];
			}
			// this.setState({ ...this.state, faces_layer: data.faces_layer });
			this.setState({ ...this.state });
		}
		this.worker.onerror = (e) => console.log("on error", e);
		// console.log("postMessage()");
		this.worker.postMessage({ graph: this.foldedForm });
	}

	componentWillUnmount() {
		if (this.worker) { this.worker.terminate(); }
	}

	componentDidUpdate(prevProps, prevState) {
		// if spacebar is pressed, set folded state
		if (this.props.spacebar && !prevProps.spacebar) {
			this.toggleFoldedState();
		}
		// this.toolValues.shiftKey = this.props.shiftKey;
	}

	updateCreasePattern () {
		// console.log("updateCreasePattern()");
		this.valid = this.props.cp.validate();
		const is_valid = this.valid.summary === "valid";
		// no matter if we are displaying the folded form or cp, we have to
		// get the nearest face from the cp.
		const foldedFace = this.props.cp
			.nearest(this.toolValues.foldedStateAnchor).face || 0;
		// if we are currently viewing the foldedForm, but the last fold
		// just invalidated the flat folded state we need to switch back to
		// viewing the cp.
		// console.log("updateCreasePattern() valid, show", is_valid, this.state.showFoldedForm);
		if (!is_valid && this.state.showFoldedForm) {
			// console.log("safely caught! showFoldedForm but cp was invalid");
			return this.setState({ ...this.state, showFoldedForm: false });
		}
		this.foldedForm = is_valid
			? this.props.cp.folded(foldedFace)
			: undefined;
		if (this.valid.summary === "valid" && this.state.showFacesLayer) {
			const hash = stringToHash(JSON.stringify(this.props.cp));
			if (hash === this.layer_hash) {
				// console.log("hashes equal");
				// if (!this.state.faces_layer) {
				// 	console.log("actually, faces layer doesn't exist");
				// 	return;
				// }
				// this.foldedForm.faces_layer = this.state.faces_layer;
			}
			else {
				// console.log("CP render() solving a new layer order");
				this.layer_hash = hash;
				if (this.worker) {
					this.worker.postMessage({ graph: this.foldedForm });
				}
			}
		}
	}

	toggleFoldedState () {
		const showFoldedForm = !this.state.showFoldedForm;
		this.setState({ ...this.state, showFoldedForm });
	}

	toggleShowFacesLayer () {
		const showFacesLayer = !this.state.showFacesLayer;
		this.setState({ ...this.state, showFacesLayer });
	}

	pointerDidUpdate () {
		// "release" events without a "press" event is considered invalid,
		// and is the effect of an event happening immediately after a press,
		// followed by a release. when this happens clear the lone release.
		if (TouchManager.releases.length && !TouchManager.presses.length) {
			TouchManager.releases = [];
		}
		// if shift is pressed, we limit certain tools to 22.5 angles only

		// try to modify the crease pattern. success or failure depends
		// on the current selected tool and how many touch events exist.
		this.tryModify();
		// console.log("pointerDidUpdate", TouchManager);
		this.toolPanel.current.forceUpdate();
	}

	toolValueDidChange (...args) {
		switch (this.props.tool) {
			case "fold-angle":
				this.toolValues.foldAngle = args[0];
				break;
			case "ray":
			case "bisect":
			case "kawasaki":
				this.toolValues.rayStop = !this.toolValues.rayStop;
				break;
			default: break;
		}
		this.toolPanel.current.forceUpdate();
	}

	repairCreasePattern () {
		// fragment cp. repair anything that can be repaired.
		this.props.cp.fragment();
		this.props.cp.populate();
		this.props.cacheStep();
		this.setState({ ...this.state });
	}

	flattenAll () {
		const lookup = { M:true, m:true, V:true, v:true, U:true, u:true };
		this.props.cp.edges_assignment
			.map((a, i) => lookup[a] ? i : undefined)
			.filter(a => a !== undefined)
			.forEach(i => {
				this.props.cp.edges_assignment[i] = "F";
				this.props.cp.edges_foldAngle[i] = 0;
			});
		this.props.cacheStep();
		this.setState({ ...this.state });
	}

	tryCreaseLine (type = "line") {
		const foldedFace = this.props.cp
			.nearest(this.toolValues.foldedStateAnchor).face || 0;
		if (Modify.tryCreaseLine(this.props.cp, TouchManager, type, this.toolValues, this.props.keyboard, this.state.showFoldedForm, foldedFace)) {
			this.props.cacheStep();
			this.setState({ ...this.state });
		}
	}
	tryAxiom () {
		const foldedFace = this.props.cp
			.nearest(this.toolValues.foldedStateAnchor).face || 0;
		const axiom = ToolsInfo[this.props.tool].axiom;
		if (Modify.tryAxiom(this.props.cp, TouchManager, axiom, this.state.showFoldedForm, foldedFace)) {
			this.props.cacheStep();
			this.setState({ ...this.state });
		}
	}
	tryBisect () {
		if (Modify.tryBisect(this.props.cp, TouchManager, this.toolValues.rayStop)) {
			this.props.cacheStep();
			this.setState({ ...this.state });
		}
	}
	tryKawasaki () {
		if (Modify.tryKawasaki(this.props.cp, TouchManager, this.toolValues.rayStop)) {
			this.props.cacheStep();
			this.setState({ ...this.state });
		}
	}
	tryRemove () {
		if (Modify.tryRemove(this.props.cp, TouchManager)) {
			this.props.cacheStep();
			this.setState({ ...this.state });
		}
	}
	tryMountainValley () {
		if (Modify.tryMountainValley(this.props.cp, TouchManager)) {
			this.props.cacheStep();
			this.setState({ ...this.state });
		}
	}
	tryFlat () {
		if (Modify.tryFlat(this.props.cp, TouchManager)) {
			this.props.cacheStep();
			this.setState({ ...this.state });
		}
	}
	tryFoldAngle () {
		const foldAngle = parseFloat(this.toolValues.foldAngle);
		if (Modify.tryFoldAngle(this.props.cp, TouchManager, foldAngle)) {
			this.props.cacheStep();
			this.setState({ ...this.state });
		}
	}
	// requires: being pressed down
	// this one is unique among the try_() methods, it does not trigger
	// this.setState() after it finishes, because it updates repeatedly
	// during the pointer drag.
	tryFoldedFormAnchor () {
		if (TouchManager.current.position.buttons === 0) { return; }
		this.toolValues.foldedStateAnchor = [
			TouchManager.current.position.x,
			TouchManager.current.position.y,
		];
	}

	tryModify (mouse, nearest) {
		switch (this.props.tool) {
			case "inspect": break;
			case "remove": return this.tryRemove();
			case "line": return this.tryCreaseLine("line");
			case "ray": return this.tryCreaseLine("ray");
			case "segment": return this.tryCreaseLine("segment");
			case "point-to-point":
			case "line-to-line":
			case "perpendicular-to":
			case "point-to-line-point":
			case "point-to-line-point-to-line":
			case "point-to-line-line": return this.tryAxiom();
			case "bisect": return this.tryBisect();
			case "kawasaki": return this.tryKawasaki();
			case "mountain-valley": return this.tryMountainValley();
			case "flat": return this.tryFlat();
			case "fold-angle": return this.tryFoldAngle();
			case "folded-form-anchor": return this.tryFoldedFormAnchor();
			default: break;
		}
	}

	render () {
		this.updateCreasePattern();
		// console.log("CP render()");
		// no matter if we are displaying the folded form or cp, we have to
		// get the nearest face from the cp.
		// const foldedFace = this.props.cp
		// 	.nearest(this.toolValues.foldedStateAnchor).face || 0;
		// const valid = this.props.cp.validate();
		// const is_valid = valid.summary === "valid";
		// if we are currently viewing the foldedForm, but the last fold
		// just invalidated the flat folded state we need to switch back to
		// viewing the cp.
		// if (!is_valid && this.state.showFoldedForm) {
		// 	// this.setState({ ...this.state, showFoldedForm: false });
		// }
		// this.state.foldedForm = is_valid
		// 	? this.props.cp.folded(foldedFace)
		// 	: undefined;
		// depending on folded state or not, get the folded origami or cp

		if (this.foldedForm !== undefined) {
			if (this.state.showFacesLayer) {
				this.foldedForm.faces_layer = this.faces_layer;
			} else {
				delete this.foldedForm.faces_layer;
			}
		}

		const origami = this.state.showFoldedForm
			? this.foldedForm
			: this.props.cp;

		TouchManager.clear();
		TouchManager.current.nearest = TouchManager
			.buildNearest(origami, TouchManager.current.position);

		return (<>
				<Toolbar
					tool={this.props.tool}
					setTool={tool => this.props.setTool(tool)}
					showFoldedForm={this.state.showFoldedForm}
					showFacesLayer={this.state.showFacesLayer}
					valid={this.valid}
					toggleFoldedState={(state) => this.toggleFoldedState()}
					toggleShowFacesLayer={(state) => this.toggleShowFacesLayer()}
					repairCreasePattern={() => this.repairCreasePattern()}
					flattenAll={() => this.flattenAll()}
				/>
				<div className="workspace" ref={this.workspace}>
					<SVGManager
						origami={origami}
						showFoldedForm={this.state.showFoldedForm}
						showFacesLayer={this.state.showFacesLayer}
						workspace={this.workspace}
						toolValues={this.toolValues}
						keyboard={this.props.keyboard}
						pointerDidUpdate={() => this.pointerDidUpdate()}
					/>
				</div>
				<div className="panels">
					<ToolPanel
						ref={this.toolPanel}
						cp={this.props.cp}
						tool={this.props.tool}
						pointer={TouchManager}
						toolValues={this.toolValues}
						toolValueDidChange={(...args) => this.toolValueDidChange(...args)}
					/>
					<FlatFoldedPanel
						cp={this.props.cp}
						folded={this.foldedForm}
						showFacesLayer={this.state.showFacesLayer}
						valid={this.valid}
						nearest={this.nearest}
					/>
				</div>
			</>
		);
	}
};

export default CreasePattern;
