// The information flow is simple:
// SVGManager class adds to this object.
// CreasePattern class removes from this object.
// and other classes are free to read from the object.
// and because this breaks everything that React (tm) stands for
// when the object gets updated we force redraw the relevant objects.

const current = { position: [0, 0], nearest: undefined };
const presses = [];
const releases = [];

const TouchManager = {
	current,
	presses,
	releases,

	clear: () => {
		Object.keys(current)
			.filter(key => key !== "position")
			.forEach(key => delete current[key]);
		while(presses.length) { presses.pop(); }
		while(releases.length) { releases.pop(); }
	}
};

TouchManager.buildNearest = (graph, coords) => {
	const nearest = graph.nearest(coords);
	if (nearest.edges_vertices) {
		nearest.edges_coords = nearest.edges_vertices
			.map(v => graph.vertices_coords[v]);
	}
	if (nearest.faces_vertices) {
		nearest.faces_coords = nearest.faces_vertices
			.map(v => graph.vertices_coords[v]);
	}
	return nearest;
};

	// clear() from old method
		// this.props.tryModify();
		// clear mouse stuff, except position, the mouse still "exists"
		// Object.keys(this.mouse)
		// 	.filter(key => key !== "position")
		// 	.forEach(key => { this.mouse[key] = undefined; });
		// Object.keys(this.nearest)
		// 	.filter(key => key !== "position")
		// 	.forEach(key => { this.nearest[key] = undefined; });

export default TouchManager;

