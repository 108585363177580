import "./Code.css";
import React from "react";
import ear from "rabbit-ear";
import Svg from "./Svg";
import "ace-builds";
import "ace-builds/webpack-resolver";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";
// import GraphInfoPanel from "../Panels/GraphInfoPanel";
// import GraphUpdatePanel from "../Panels/GraphUpdatePanel";

class Code extends React.Component {

	state = {
		cp: this.props.cp,
		// code: "",
		// codeHasChanged: false,
	}

	// on props changed
	// when cp is updated, must trigger data = cp
	// and render()

	constructor(props) {
		super(props);
		this.svgComponent = React.createRef();
		this.svgParent = React.createRef();
		// this.setState({cp: props.cp });
	}

	componentDidMount() {
		this.svgComponent.current.forceUpdate();
		// this.setState({ code: this.props.code });
	}

	onChange(e) {
		this.props.setCode(e);
		// this.setState({ code: e, codeHasChanged: true });
	}

	tryUpdate() {
    try {
      // if (typeof app.didBeginUpdate === "function") {
      //   app.didBeginUpdate();
      // }
      let first_line = `let cp = ear.cp(${JSON.stringify(this.props.cp)})`;
      let last_line = `;\ncp;`;
      const cp = eval(`${first_line}\n${this.props.code}\n${last_line}`);
      this.setState({ cp })
      // app.dom.console.innerHTML = "";
    } catch (err) {
    	console.log("Error", err);
      // app.dom.console.innerHTML = "<p>" + err + "</p>";
    }
	}

	previewDidPress () {
		this.tryUpdate();
		this.svgComponent.current.forceUpdate();
	}

	saveDidPress () {
		// todo: check if code has changed, if not just use the already built cp
		this.tryUpdate();
		// todo: why do we need to deep copy this object?
		this.props.setCP(ear.cp(JSON.parse(JSON.stringify(this.state.cp))));
		this.props.setView("crease pattern");
	}

	render() {
		const bounds = this.state.cp.boundary.boundingBox();
		const viewBox = [
			bounds.min[0], bounds.min[1], bounds.span[0], bounds.span[1]
		];
		const vmin = bounds.span[0] < bounds.span[1]
			? bounds.span[0]
			: bounds.span[1];
		// const vmin = 1;
		return (<>
			<div className="code">
				<AceEditor
					className="code-editor"
					width="100%"
					height="100%"
					fontSize={18}
					mode="javascript"
					theme="monokai"
					onChange={(e) => this.onChange(e)}
					value={this.props.code}
					name="need-a-unique-id"
					editorProps={{ $blockScrolling: true }}
				/>
				<div className="code-preview" ref={this.svgParent}>
					<Svg
						cp={this.state.cp}
						ref={this.svgComponent}
						svgParent={this.svgParent}
						bounds={bounds}
						vmin={vmin}
						padding={vmin / 40}
						strokeWidth={vmin / 200}
					>
					{/*<GraphInfoPanel />
					<GraphUpdatePanel
						onUpdatePress={() => this.tryUpdate()}
						codeIsValid={this.state.codeIsValid}
						codeChanged={this.state.codeHasChanged}/>*/}
					</Svg>
				</div>
				<div className="footer">
					<button className="code-button" onClick={() => this.previewDidPress()}>Preview</button>
					<button className="code-button" onClick={() => this.saveDidPress()}>Save</button>
				</div>
			</div>
		</>);
	}
}

export default Code;
