import React from "react";
import ear from "rabbit-ear";

// const capitalize = word => word
//   .toLowerCase()
//   .replace(/\w/, firstLetter => firstLetter.toUpperCase());

const polygonName = [null, "monogon", "bigon", "triangle", "quadrilateral", "pentagon", "hexagon", "septagon", "octagon", "nonagon", "decagon", "undecagon", "dodecagon"];

const InspectToolPanel = (props) => {

	const componentCount = cp => {
		const singular = ["vertex", "edge", "face"];
		const nearest = props.pointer.current && props.pointer.current.nearest
			? singular.map(s => props.pointer.current.nearest[s])
				.map(n => n === undefined ? "" : `(#${n})`)
			: ["", "", ""];
		return ["vertices", "edges", "faces"]
			.map((string, i) => (
				<li key={i}>
					<b>{ear.graph.count[string](cp)}</b> {string} <b>{nearest[i]}</b>
				</li>
			));
	};

	const prefix = ["x", "y", "z"];

	const coordinateString = props.pointer.current.nearest && props.pointer.current.nearest.vertices_coords
		? `${props.pointer.current.nearest.vertices_coords
				.map((n, i) => `${prefix[i]}: ${n.toFixed(4)}`)
				.join(", ")}`
		: "";

	const faceName = props.pointer.current.nearest && props.pointer.current.nearest.faces_vertices
		? polygonName[props.pointer.current.nearest.faces_vertices.length] || `${props.pointer.current.nearest.faces_vertices.length}-gon`
		: "";

	const assignmentName = props.pointer.current.nearest
		? ear.graph.edges_assignment_names[props.pointer.current.nearest.edges_assignment] || "unknown"
		: "";

	const foldAngle = props.pointer.current.nearest
		? Math.abs(props.pointer.current.nearest.edges_foldAngle)
		: NaN;
	const foldAngleName = isNaN(foldAngle) || foldAngle === 0
		? ""
		: `${foldAngle}\u00B0`;
	
	return (<>
		<ul>
			{componentCount(props.cp)}
		</ul>
		<p><b>[{coordinateString}]</b></p>
		<p><b>{faceName}</b>{faceName === "" ? "no" : ""} face</p>
		<p><b>{foldAngleName}</b> <b>{assignmentName}</b> crease</p>
	</>);
};

export default InspectToolPanel;
