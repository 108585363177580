
const style = {
	boundaries: {
		"stroke-width": 0.0025,
	},
	faces: {
		display: "none"
	},
	edges: {
		unassigned: { stroke: "#000"  },
		flat:       { stroke: "#ccc"  },
		mountain:   { stroke: "#e53"  },
		valley:     { stroke: "#38c"  },
		boundary:   { stroke: "black" },
	},
	vertices: {
		display: "none"
	}
};

export default style;
