export const selection = [
	"inspect",
	"remove",
];

export const simple = [
	"line",
	"ray",
	"segment",
];

export const axioms = [
	"point-to-point",
	"line-to-line",
	"perpendicular-to",
	"point-to-line-point",
	"point-to-line-point-to-line",
	"point-to-line-line",
];

export const other = [
	"bisect",
	"kawasaki",
];

export const modify = [
	"mountain-valley",
	"fold-angle",
	"flat",
];

export const all_tools = []
	.concat(selection)
	.concat(simple)
	.concat(axioms)
	.concat(modify);
