import React from "react";
import ear from "rabbit-ear";
import { make_kawasaki_vectors, nearest_ray, bisect } from "../../Math";
import { sixteenth } from "../Modify";

const edge_colors = {
	B: "black", b: "black",
	M: "#e53",  m: "#e53",
	V: "#38c",  v: "#38c",
	F: "#ddd",  f: "#ddd",
	U: "#aaa",  u: "#aaa",
};

// todo: see if we can get rid of the this.props.origami reference
class Group extends React.Component {
	// the svg <g> group layer
	group = null;

	arrowHeadStyle = {
		width: this.props.strokeWidth * 14,
		height: this.props.strokeWidth * 18,
	};

	constructor(props) {
		super(props);
		this.container = React.createRef();
		this.group = ear.svg.g()
	}

	componentDidMount() {
		this.container.current.appendChild(this.group);
	}

	drawVertex (coords) {
		if (!coords) { return; }
		this.group.circle(coords)
			.radius(this.props.strokeWidth * 3)
			.fill("#e53")
			.stroke("black")
			.strokeWidth(this.props.strokeWidth * 1.5);
	}

	drawEdge (coords, assignment) {
		if (!coords) { return; }
		this.group.line(coords)
			.stroke(edge_colors[assignment] || "black")
			.strokeWidth(this.props.strokeWidth * 3)
			.strokeLinecap("round");
	}

	drawNearestVertex () {
		if (!this.props.pointer.current.nearest) { return; }
		this.drawVertex(this.props.pointer.current.nearest.vertices_coords);
	}

	drawNearestEdge () {
		if (!this.props.pointer.current.nearest) { return; }
		this.drawEdge(this.props.pointer.current.nearest.edges_coords,
			this.props.pointer.current.nearest.edges_assignment);
	}

	arrowPointToPoint () {
		const lastPress = this.props.pointer.presses[this.props.pointer.presses.length - 1];
		const start = lastPress.nearest.vertices_coords;
		const end = this.props.pointer.current.nearest.vertices_coords;
		// if endpoints are the same, don't draw an arrow
		if (ear.math.equivalent_vector2(start, end)) { return; }
		this.group.arrow(start, end)
			.stroke("black")
			.strokeWidth(this.props.strokeWidth * 2)
			.bend(0.3)
			.head(this.arrowHeadStyle)
			.tail(this.arrowHeadStyle);
	}

	creaseLineArrow (start, end, type="line") {
		const arrow = this.group.arrow(start, end)
			.stroke("black")
			.strokeWidth(this.props.strokeWidth * 2)
			.strokeLinecap("round")
			.strokeDasharray(`${this.props.strokeWidth * 2} ${this.props.strokeWidth * 4}`)
		if (type==="line" || type === "ray") { arrow.head(this.arrowHeadStyle); }
		if (type==="line") { arrow.tail(this.arrowHeadStyle); }
	}

	drawSixteenthArrow () {

	}
	// arrow16Vector(head, tail) {
	// 	if (!this.props.mouse.vector16) { return; }
	// 	const magnitude = ear.math.distance2(
	// 		this.props.nearest.press.vertices_coords,
	// 		this.props.mouse.position);
	// 	const arrow = this.group.arrow(
	// 		this.props.nearest.press.vertices_coords,
	// 		ear.vector(this.props.nearest.press.vertices_coords)
	// 			.add(this.props.mouse.vector16.scale(magnitude))
	// 	).stroke("black")
	// 		.strokeWidth(this.props.vmin / 100);
	// 	const this.arrowHeadStyle = {
	// 		width: this.props.vmin / 30,
	// 		height: this.props.vmin / 20,
	// 	};
	// 	if (head) { arrow.head(this.arrowHeadStyle); }
	// 	if (tail) { arrow.tail(this.arrowHeadStyle); }
	// }

	drawAllStates() {
		switch (this.props.tool) {
			case "inspect":
				this.drawNearestEdge();
				this.drawNearestVertex();
				break;
			case "remove": this.drawNearestEdge(); break;
			case "line":
			case "ray":
			case "segment":
				if (this.props.pointer.presses.length && this.props.keyboard.shiftKey && this.props.tool !== "segment") {
					const vector = ear.math.subtract2(
						[this.props.pointer.current.position.x, this.props.pointer.current.position.y],
						this.props.pointer.presses[0].nearest.vertices_coords);
					const vector22_5 = sixteenth(vector);
					const endpoint = ear.math.add2(
						this.props.pointer.presses[0].nearest.vertices_coords,
						ear.math.scale2(vector22_5, ear.math.magnitude2(vector)));
					this.creaseLineArrow(
						this.props.pointer.presses[0].nearest.vertices_coords,
						endpoint,
						this.props.tool);
					return;
				}
				// only draw the nearest vertex if the mouse isn't pressed
				if (this.props.pointer.presses.length) {
					this.drawVertex(this.props.pointer.presses[0].nearest.vertices_coords);
					this.drawNearestVertex();
					this.creaseLineArrow(
						this.props.pointer.presses[0].nearest.vertices_coords,
						this.props.pointer.current.nearest.vertices_coords,
						this.props.tool);
				}
				else {
					this.drawNearestVertex();
				}
				break;
			case "point-to-point":
				if (this.props.pointer.presses.length
					&& this.props.pointer.presses[0].nearest) {
					this.drawVertex(this.props.pointer.presses[0].nearest.vertices_coords);
				}
				this.drawNearestVertex();
				break;
			case "line-to-line":
			case "bisect":
				if (this.props.pointer.presses.length
					&& this.props.pointer.presses[0].nearest) {
					this.drawEdge(this.props.pointer.presses[0].nearest.edges_coords,
						this.props.pointer.presses[0].nearest.edges_assignment);
				}
				this.drawNearestEdge();
				break;
			case "perpendicular-to":
				if (this.props.pointer.presses.length
					&& this.props.pointer.presses[0].nearest) {
					this.drawEdge(this.props.pointer.presses[0].nearest.edges_coords,
						this.props.pointer.presses[0].nearest.edges_assignment);
					this.drawNearestVertex();
				} else {
					this.drawNearestEdge();
				}
				break;
			case "point-to-line-point":
				if (this.props.pointer.presses.length
					&& this.props.pointer.releases.length) {
					// step 3
					this.drawVertex(this.props.pointer.presses[0].nearest.vertices_coords);
					this.drawEdge(this.props.pointer.releases[0].nearest.edges_coords,
						this.props.pointer.releases[0].nearest.edges_assignment);
					this.drawNearestVertex();
				} else if (this.props.pointer.presses.length) {
					// step 2
					this.drawVertex(this.props.pointer.presses[0].nearest.vertices_coords);
					this.drawNearestEdge();
				} else {
					// step 1
					this.drawNearestVertex();
				}
				break;
			case "point-to-line-point-to-line":
				if (this.props.pointer.presses.length > 1
					&& this.props.pointer.releases.length) {
					// step 4
					this.drawVertex(this.props.pointer.presses[0].nearest.vertices_coords);
					this.drawEdge(this.props.pointer.releases[0].nearest.edges_coords,
						this.props.pointer.releases[0].nearest.edges_assignment);
					this.drawVertex(this.props.pointer.presses[1].nearest.vertices_coords);
					this.drawNearestEdge();
				} else if (this.props.pointer.presses.length
					&& this.props.pointer.releases.length) {
					// step 3
					this.drawVertex(this.props.pointer.presses[0].nearest.vertices_coords);
					this.drawEdge(this.props.pointer.releases[0].nearest.edges_coords,
						this.props.pointer.releases[0].nearest.edges_assignment);
					this.drawNearestVertex();
				} else if (this.props.pointer.presses.length) {
					// step 2
					this.drawVertex(this.props.pointer.presses[0].nearest.vertices_coords);
					this.drawNearestEdge();
				} else {
					// step 1
					this.drawNearestVertex();
				}
				break;
			case "point-to-line-line":
				if (this.props.pointer.presses.length
					&& this.props.pointer.releases.length) {
					// step 3
					this.drawVertex(this.props.pointer.presses[0].nearest.vertices_coords);
					this.drawEdge(this.props.pointer.releases[0].nearest.edges_coords,
						this.props.pointer.releases[0].nearest.edges_assignment);
					this.drawNearestEdge();
				} else if (this.props.pointer.presses.length) {
					// step 2
					this.drawVertex(this.props.pointer.presses[0].nearest.vertices_coords);
					this.drawNearestEdge();
				} else {
					// step 1
					this.drawNearestVertex();
				}
				break;
			case "kawasaki":
				const vertex = this.props.pointer.current.nearest.vertex;
				const kawasaki_vectors_scaled = make_kawasaki_vectors(this.props.origami, vertex)
					.map(vector => ear.math.scale2(vector, this.props.vmin * 0.15));
				const nearest_solution = nearest_ray(
					kawasaki_vectors_scaled,
					this.props.origami.vertices_coords[vertex],
					[this.props.pointer.current.position.x, this.props.pointer.current.position.y]);
				const colors = kawasaki_vectors_scaled
					.map((_, i) => i === nearest_solution ? "#e53" : "black");
				kawasaki_vectors_scaled
					.map(vector => [
						this.props.origami.vertices_coords[vertex],
						ear.math.add2(this.props.origami.vertices_coords[vertex], vector)])
					.forEach((segment, i) => this.group.arrow(...segment)
						.stroke(colors[i])
						.fill(colors[i])
						.strokeWidth(this.props.strokeWidth * 2)
						.strokeLinecap("round")
						.strokeDasharray(`${this.props.strokeWidth * 2} ${this.props.strokeWidth * 4}`)
						.head(this.arrowHeadStyle));
				this.drawNearestVertex();
			break;
			case "mountain-valley":
			case "flat":
			case "fold-angle": this.drawNearestEdge(); break;
			case "folded-form-anchor":
				this.group.circle(this.props.toolValues.foldedStateAnchor)
					.radius(this.props.strokeWidth * 3)
					.fill("#e53")
					.stroke("black")
					.strokeWidth(this.props.strokeWidth * 1.5);
			break;
			default: break;
		}
	}

	drawPressState() {
		if (!this.props.pointer.presses.length) { return; }
		switch(this.props.tool) {
			case "inspect": break;
			case "remove": break;
			// case "line": this.arrow16Vector(true, true); break;
			// case "ray": this.arrow16Vector(true, false); break;
			case "point-to-point": this.arrowPointToPoint(); break;
			case "line-to-line": break;
			case "perpendicular-to": break;
			case "point-to-line-point": break;
			case "point-to-line-point-to-line": break;
			case "point-to-line-line": break;
			case "bisect":
				if (!this.props.pointer.presses.length || !this.props.pointer.current) { return; }
				// console.log("Touch", this.props.pointer);
				const ray = bisect(this.props.pointer.presses[0], this.props.pointer.current);
				if (!ray) { break; }
				const segment = this.props.origami.boundary.clip(ray.scale(this.props.vmin * 2));
				if (!segment) { break; }
				this.group.line(...segment)
					.stroke("black")
					.strokeWidth(this.props.strokeWidth * 2)
					.strokeLinecap("round")
					.strokeDasharray(`${this.props.strokeWidth * 2} ${this.props.strokeWidth * 4}`)
				break;
			case "kawasaki": break;
			case "mountain-valley": break;
			case "flat": break;
			case "fold-angle": break;
			case "folded-form-anchor": break;
			default: break;
		}
	}

	render() {
		this.arrowHeadStyle = {
			width: this.props.strokeWidth * 6,
			height: this.props.strokeWidth * 8,
		};
		this.group.removeChildren();
		this.drawAllStates();
		this.drawPressState();
		return (
			<g className="above-group" ref={this.container}></g>
		);
	}
};

export default Group;
