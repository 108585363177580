import React from "react";
import "./Panel.css";
import Minus from "./minus-solid.svg";
import Plus from "./plus-solid.svg";

const Panel = (props) => {

	let [isCollapsed, setIsCollapsed] = React.useState(false);

	return (
		<div className="panel">
			<div className="panel-titlebar">
				<span>{props.title}</span>
				<div
					onClick={() => setIsCollapsed(!isCollapsed)}
					className="panel-button">
					<img 
						src={isCollapsed ? Plus : Minus}
						alt="toggle collapse"/>
				</div>
			</div>
			{isCollapsed
				? (<></>)
				: (<div className="panel-body">{props.children}</div>)
			}
		</div>
	);
}

export default Panel;
