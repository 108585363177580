import React, { useState } from 'react';
import "./DragAndDrop.css";

const DragAndDrop = (props) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleDragEnter = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsHovered(true);
  };
  const handleDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsHovered(false);
  };
  const handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = 'copy';
    setIsHovered(true);
  };
  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsHovered(false);
    let files = [...e.dataTransfer.files];
    const file = files[0];    
    const reader = new FileReader()
    reader.onload = event => {
      try { props.fileDidLoad(JSON.parse(event.target.result)); }
      catch (error) { console.error(error); }
    };
    reader.onerror = error => console.error(error);
    reader.readAsText(file)
  }

  window.removeEventListener('dragenter', handleDragEnter);
  window.addEventListener('dragenter', handleDragEnter);

  return (
    <div className={isHovered ? 'drop-zone visible' : 'drop-zone hidden'}
      onDrop={e => handleDrop(e)}
      onDragOver={e => handleDragOver(e)}
      onDragEnter={e => handleDragEnter(e)}
      onDragLeave={e => handleDragLeave(e)}
    >
      <div className="drop-zone-inside"></div>
      <div className="drop-zone-cutout"></div>
    </div>
  );
};
export default DragAndDrop;