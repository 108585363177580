import React from "react";
import ear from "rabbit-ear";

class Group extends React.Component {
	// the svg <g> group layer
	group = null;

	constructor(props) {
		super(props);
		this.container = React.createRef();
		this.group = ear.svg.g()
	}

	componentDidMount() {
		this.container.current.appendChild(this.group);
	}

	render() {
		this.group.removeChildren();
		if (!this.props.showFoldedForm) {
			const polygon = ear.graph
				.get_boundary(this.props.origami)
				.vertices
				.map(v => this.props.origami.vertices_coords[v]);
			this.group.polygon(polygon)
				.fill("white")
				.stroke("black")
				.strokeWidth(this.props.strokeWidth * 2);
		}
		return (
			<g className="paper-group" ref={this.container}></g>
		);
	}
};

export default Group;
