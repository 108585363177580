// this was helpful to get three js hooked up
// https://blog.bitsrc.io/starting-with-react-16-and-three-js-in-5-minutes-3079b8829817

import "./Simulator.css";
import React from "react";
import * as THREE from "three";
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls";
import OrigamiSimulator from "./origami-simulator/";
import DragControls from "./origami-simulator/dragControls";
import Toolbar from "../Toolbars/SimulatorToolbar";
// only needed for fold-angle set
import ear from "rabbit-ear";

/**
 * @description length of 3D vector
 */
const length3 = v => Math.sqrt((v[0] ** 2) + (v[1] ** 2) + (v[2] ** 2));
/**
 * @description compute the angle in radians between two 3D vectors
 */
const angle3 = (a, b) => {
	const dot_prod = (a[0] * b[0] + a[1] * b[1] + a[2] * b[2]);
	const denom = length3(a) * length3(b);
	return Math.acos(dot_prod / denom);
};

class Simulator extends React.Component {

	state = {
		tool: "rotate",
		strain: false,
		isActive: true,
		foldAmount: 0.0,
	}

	foldAmountDidChange(foldAmount) {
		this.setState({ foldAmount });
		this.simulator.foldAmount = foldAmount;
	}

	strainDidPress() {
		this.setState({ strain: !this.state.strain });
	}

	isActiveDidPress() {
		this.setState({ isActive: !this.state.isActive });
		this.state.isActive ? this.simulator.stop() : this.simulator.start();
	}

	saveFoldAngle() {
		// in this model, the faces_vertices will differ from the this.props.cp,
		// because the faces have been triangulated. however, the edges and
		// vertices will be the same.
		const model = this.simulator.model;
		const vertex_pairs_faces = {};
		const faces_vertices_coords = model.faces_vertices
			.map(vertices => vertices.map(v => [
				model.positions[v * 3 + 0],
				model.positions[v * 3 + 1],
				model.positions[v * 3 + 2],
			]));
		const faces_normals = faces_vertices_coords.map(verts => {
			const a = ear.math.subtract(verts[1], verts[0]);
			const b = ear.math.subtract(verts[2], verts[0]);
			return ear.math.cross3(ear.math.normalize(a), ear.math.normalize(b));
		});
		// because the faces have been triangulated and differ from the original
		// graph, we need to rebuild a new edges_faces.
		// iterate over every face (faces_vertices), gather each consecutive
		// pair of vertices as a space-separated string of two numbers,
		// use make_vertices_to_edge_bidirectional to convert vertices pairs
		// into an edge, then store the adjacent faces into that edge's spot.
		model.faces_vertices
			.forEach((vertices, face) => vertices
				.forEach((vertex, i, arr) => {
					const next = arr[(i + 1) % arr.length];
					const pair = [vertex, next]
						.sort((a, b) => a - b)
						.join(" ");
					if (vertex_pairs_faces[pair] === undefined) {
						vertex_pairs_faces[pair] = [];
					}
					vertex_pairs_faces[pair].push(face);
				}));
		const edge_map = ear.graph
			.make_vertices_to_edge_bidirectional(this.props.cp);
		const edges_faces = [];
		Object.keys(vertex_pairs_faces).forEach(key => {
			edges_faces[edge_map[key]] = vertex_pairs_faces[key];
		});
		// get the angle between two adjacent faces, however the angle
		// will be positive, use the edges_assignment as a reference
		// and for mountain creases, flip the sign of the foldAngle to negative
		const flip_angle = { M: true, m: true };
		const edges_foldAngle = edges_faces
			.map(faces => faces.length < 2
				? 0
				: angle3(faces_normals[faces[0]], faces_normals[faces[1]]))
			.map((angle, i) => flip_angle[this.props.cp.edges_assignment[i]]
				? -angle
				: angle);
		// store the fold angle back into the crease pattern. convert to degrees.
		this.props.cp.edges_foldAngle = edges_foldAngle
			.map(radians => radians * ear.math.R2D);
		// trigger the simulator to reload the crease pattern.
		// todo: this could be done a little more delicately by only updating
		// the desired fold angle inside the same model.
		this.simulator.load(this.props.cp);
	}

	componentDidMount() {
		this.setupScene();
		this.setupLighting();
		this.setupLoop();
		window.addEventListener("resize", this.handleWindowResize.bind(this));
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.handleWindowResize);
		window.cancelAnimationFrame(this.animationID);
		// console.log("--- dealloc: Origami Simulator");
		this.simulator.dealloc();
		// console.log("--- dealloc: THREE.JS renderer, camera, controls");
		this.renderer.renderLists.dispose();
		this.renderer.dispose();
		this.camera = null;
		this.rotateControls.dispose();
		this.dragControls.dealloc();
		this.el.removeChild(this.renderer.domElement);
	}

	handleWindowResize() {
		if (!this.el) { return; }
		// lol this doesn't work, it uses the already established canvas
		// to infer the "new" screen size. spoiler, it's the old size.
		const width = this.el.clientWidth;
		const height = this.el.clientHeight;
		// console.log("width height", width, height);
		this.renderer.setSize(width, height);
		this.camera.aspect = width / height;
		this.camera.updateProjectionMatrix();
		this.rotateControls.handleResize();
	};

	setupScene() {
		const width = this.el.clientWidth;
		const height = this.el.clientHeight;
		// console.log("+++ initialize: THREE.JS, new scene, camera, controls");
		this.renderer = new THREE.WebGLRenderer({ antialias: true });
		this.scene = new THREE.Scene();
		this.camera = new THREE.PerspectiveCamera(45, width / height, 0.01, 100);
		// console.log(this.camera)
		this.rotateControls = new TrackballControls(this.camera, this.el);
		// console.log("+++ initialize: Origami Simulator");
		this.simulator = OrigamiSimulator({
			renderer: this.renderer,
			scene: this.scene,
			camera: this.camera,
		});

		this.dragControls = new DragControls({
			renderer: this.renderer,
			camera: this.camera,
			simulator: this.simulator,
		});
		this.dragControls.nodePositionsDidChange = () => {
			this.simulator.modelDidChange();
		};

		this.renderer.setPixelRatio(window.devicePixelRatio);
		this.renderer.setSize(width, height);
		this.scene.background = new THREE.Color("#eee");
		this.camera.position.z = 2;
		this.camera.lookAt(0, 0, 0);
		this.camera.up = new THREE.Vector3(0, 1, 0);

		this.rotateControls.rotateSpeed = 4.0;
		this.rotateControls.zoomSpeed = 1.2;
		this.rotateControls.panSpeed = 0.8;
		this.rotateControls.dynamicDampingFactor = 0.2;

		this.rotateControls.maxDistance = 30;
		this.rotateControls.minDistance = 0.1;

		this.el.appendChild(this.renderer.domElement);
		
		this.simulator.load(this.props.cp);

		const sphereGeometry = new THREE.SphereGeometry(0.03, 32, 16);
		const faceGeometry = new THREE.BufferGeometry();
		const sphereMaterial = new THREE.MeshBasicMaterial({ color: 0xffcc55 });
		const faceMaterial = new THREE.MeshBasicMaterial({ side: THREE.DoubleSide, color: 0x77cc55 });
		// two triangle faces, three vertices with x, y, z
		const buffer = new Float32Array(Array(2 * 3 * 3).fill(0.0));
		const position = new THREE.BufferAttribute(buffer, 3);
		faceGeometry.setAttribute("position", position);
		this.highlightedVertex = new THREE.Mesh(sphereGeometry, sphereMaterial);
		this.highlightedFace = new THREE.Mesh(faceGeometry, faceMaterial);

		this.scene.add(this.highlightedVertex);
		this.scene.add(this.highlightedFace);
	}

	setupLighting() {
		// octahedral (vertices) arrangement of lights
		const radius = 10;
		const lights = [
			[1, 0, 0],
			[0, 1, 0],
			[0, 0, 1],
			[-1, 0, 0],
			[0, -1, 0],
			[0, 0, -1],
		].map(vec => vec.map(n => n * radius))
			.map(vec => {
			const light = new THREE.PointLight(0xffffff, 1, 0);
			light.position.set(...vec);
			return light;
		});
		lights.forEach(light => this.scene.add(light));
	}

	highlightVertex(nearest) {
		const visible = (nearest && nearest.vertex != null);
		this.highlightedVertex.visible = visible;
		if (!visible) { return; }
		this.highlightedVertex.position.set(
			this.simulator.model.positions[nearest.vertex * 3 + 0],
			this.simulator.model.positions[nearest.vertex * 3 + 1],
			this.simulator.model.positions[nearest.vertex * 3 + 2]
		);
	};

	highlightFace(nearest) {
		const visible = (nearest && nearest.face != null);
		this.highlightedFace.visible = visible;
		if (!visible) { return; }
		const zDistance = 0.001;
		// const zDistance = 0.1;
		const normal = nearest.face_normal.clone().multiplyScalar(zDistance);
		const normals = [normal, normal.clone().multiplyScalar(-1)];
		const facePoints = nearest.face_vertices
			.map(vert => [0, 1, 2].map(i => this.simulator.model.positions[vert * 3 + i]))
			.map(vertex => new THREE.Vector3(...vertex));
		[facePoints, facePoints]
			.map((tri, i) => tri.map(p => p.clone().add(normals[i])))
			.forEach((tri, i) => tri.forEach((p, j) => {
				this.highlightedFace.geometry.attributes.position.array[i * 9 + j * 3 + 0] = p.x;
				this.highlightedFace.geometry.attributes.position.array[i * 9 + j * 3 + 1] = p.y;
				this.highlightedFace.geometry.attributes.position.array[i * 9 + j * 3 + 2] = p.z;
			}));
		this.highlightedFace.geometry.attributes.position.needsUpdate = true;
		this.highlightedFace.geometry.computeBoundingBox();
		this.highlightedFace.geometry.computeBoundingSphere();
	};

	setupLoop() {
		const animate = () => {
			this.animationID = window.requestAnimationFrame(animate);
			this.rotateControls.update();
			if (this.dragControls.nearest) { // if show nearest
				this.highlightVertex(this.dragControls.nearest);
				this.highlightFace(this.dragControls.nearest)
			}
			this.renderer.render(this.scene, this.camera);
		};
		animate();
	}

	render() {
		if (this.simulator) {
			this.simulator.strain = this.state.strain;
			// this.simulator.grab = this.state.tool === "grab";
			this.rotateControls.enabled = this.state.tool === "rotate"
			this.dragControls.enabled = this.state.tool === "grab";
			// console.log(this.rotateControls);
		}
		return (<>
			<Toolbar
				tool={this.state.tool}
				setTool={tool => this.setState({ tool })}
				strain={this.state.strain}
				strainDidPress={() => this.strainDidPress()}
				isActive={this.state.isActive}
				isActiveDidPress={() => this.isActiveDidPress()}
				foldAmount={this.state.foldAmount}
				foldAmountDidChange={a => this.foldAmountDidChange(a)}
				saveFoldAngle={() => this.saveFoldAngle()}
				/>
			<div className="simulator" ref={ref => (this.el = ref)} />
		</>);
		// return (<div ref={ref => (this.mount = ref)}></div>);
	}
}

export default Simulator;