import React from "react";
import "./AxiomToolPanel.css";

// const capitalize = word => word
//   .toLowerCase()
//   .replace(/\w/, firstLetter => firstLetter.toUpperCase());

const axiomParamsSatisfied = (axiom, presses, releases) => {
	switch (axiom) { // careful, number type, not string
		case 1:
		case 2:
		case 3:
		case 4: return [presses[0], releases[0]];
		case 6: return [presses[0], releases[0], presses[1], releases[1]];
		case 5:
		case 7: return [presses[0], releases[0], presses[1]];
		default: return [];
	}
};

const AxiomToolPanel = (props) => {

// axiom: 1
// desc: "through points"
// description: "crease through two points"
// input: Array [ "press on a point", "release on another point" ]
// lines: 0
// points: 2
// title: "Line"

	// const unitize = (number, singular) => number === 1
	// 	? `${number} ${singular}`
	// 	: `${number} ${singular}s`;

	// const makeInstructions = () => {
	// 	const points = props.toolInfo.points
	// 		? unitize(props.toolInfo.points, "point") : undefined;
	// 	const lines = props.toolInfo.lines
	// 		? unitize(props.toolInfo.lines, "line") : undefined;
	// 	return "needs " + [points, lines]
	// 		.filter(a => a !== undefined)
	// 		.join(" and ");
	// };

	const is_done = {
		true: "finished",
		false: "not-finished",
	}

	const satisfied = axiomParamsSatisfied(props.toolInfo.axiom, props.pointer.presses, props.pointer.releases)
		.map(el => el !== undefined);
	let current_step = 0;
	for (; current_step < satisfied.length; current_step++) {
		if (!satisfied[current_step]) break;
	}
	const stepClass = satisfied
		.map((s, i) => i === current_step ? "current" : is_done[s]);

	const rayParameter = () => {
		if (props.toolInfo.title !== "Bisect"
			&& props.toolInfo.title !== "Ray"
			&& props.toolInfo.title !== "Kawasaki") { return (<></>); }
		return (<>
			<hr />
			<label>
				<input type="checkbox"
					defaultChecked={props.toolValues.rayStop}
					onChange={props.toolValueDidChange}
					style={{ marginRight: "0.5rem" }}
				/>
				stop at intersection
			</label>
		</>);
	};

	return (<>
		<h5>{props.toolInfo.desc}</h5>
		<p className="description">{props.toolInfo.description}</p>
		{/*<p>{makeInstructions()}</p>*/}
		<ol>
			{props.toolInfo.input.map((str, i) => <li className={stepClass[i]} key={i}>{str}</li>)}
		</ol>
		{rayParameter()}
	</>);
};

export default AxiomToolPanel;
