import React from "react";
import ear from "rabbit-ear";

const AssignmentToolPanel = (props) => {
	const assignmentName = props.pointer.current.nearest
		? ear.graph.edges_assignment_names[props.pointer.current.nearest.edges_assignment] || "unknown"
		: "";
	const foldAngle = props.pointer.current.nearest
		? Math.abs(props.pointer.current.nearest.edges_foldAngle)
		: NaN;
	const foldAngleName = isNaN(foldAngle) || foldAngle === 0
		? ""
		: `${foldAngle}\u00B0`;
	
	return (<>
		<p><b>{foldAngleName}</b> <b>{assignmentName}</b> crease</p>
	</>);
};

export default AssignmentToolPanel;