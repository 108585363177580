import React from "react";
import ear from "rabbit-ear";

const FoldAngleToolPanel = (props) => {

	const angleOnChange = (e) => {
		props.toolValueDidChange(e.target.value)
	}

	const assignmentName = props.pointer.current.nearest
		? ear.graph.edges_assignment_names[props.pointer.current.nearest.edges_assignment] || "unknown"
		: "";
	const foldAngle = props.pointer.current.nearest
		? Math.abs(props.pointer.current.nearest.edges_foldAngle)
		: NaN;
	const foldAngleName = isNaN(foldAngle) || foldAngle === 0
		? ""
		: `${foldAngle}\u00B0`;
	
	return (<>
		<p><b>{foldAngleName}</b> <b>{assignmentName}</b> crease</p>
		<p>to:</p>
		<input type="text" value={props.toolValues.foldAngle} onChange={angleOnChange}></input>
	</>);
};

export default FoldAngleToolPanel;
