import React from "react";
import ear from "rabbit-ear";

class Group extends React.Component {
	// the svg <g> group layer
	group = null;

	constructor(props) {
		super(props);
		this.container = React.createRef();
		this.group = ear.svg.g()
	}

	componentDidMount() {
		this.container.current.appendChild(this.group);
	}

	drawAllStates() {
		switch (this.props.tool) {
			case "inspect":
				if (!this.props.pointer.current.nearest
					|| !this.props.pointer.current.nearest.faces_coords) {
					return;
				}
				this.group.polygon(this.props.pointer.current.nearest.faces_coords)
					.stroke("none")
					.fill("#fb4");
			break;
			case "remove": break;
			case "line": break;
			case "ray": break;
			case "segment": break;
			case "point-to-point": break;
			case "line-to-line": break;
			case "perpendicular-to": break;
			case "point-to-line-point": break;
			case "point-to-line-point-to-line": break;
			case "point-to-line-line": break;
			case "bisect": break;
			case "kawasaki": break;
			case "mountain-valley": break;
			case "flat": break;
			case "fold-angle": break;
			case "folded-form-anchor":
				const nearest = this.props.origami
					.nearest(this.props.toolValues.foldedStateAnchor);
				if (nearest.face !== undefined) {
					this.group.polygon(nearest.faces_vertices
						.map(v => this.props.origami.vertices_coords[v]))
						.stroke("none")
						.fill("#fb4");
				}
				break;
			default: break;
		}
	}

	render() {
		this.group.removeChildren();
		this.drawAllStates();
		return (
			<g className="below-group" ref={this.container}></g>
		);
	}
};

export default Group;
